import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col} from 'react-bootstrap'
import {SectionWrap} from './section.style'
import SectionTitle from '../../../../components/ui/section-title'
import SwiperSlider from '../../../../components/ui/swiper'
import Testimonial from '../../../../components/testimonial/layout-one'
import author1img from '../../../../data/images/avatar/avatar-01-90x90.jpg'
import author2img from '../../../../data/images/avatar/avatar-02-90x90.jpg'
import author3img from '../../../../data/images/avatar/avatar-03-90x90.jpg'

const Section = ({SectionTitleStyle, slider ,sliderStyle}) => {
    const testimonials = [
        {
            id: 1,
            authorImg: author1img,
            authorName: 'Katie Twells',
            authroRole: 'Client',
            review: 'Chris and Fernando have been brilliant to work with. Very professional, always on hand to take a call and offer an efficient and high quality service. I have peace of mind that I can call on a team that I can trust, charge fairly, deal with me in an open, honest way and deliver to an excellent standard. Thank you PropertyPac.',
            rating: 5
        },
        {
            id: 2,
            authorImg: author2img,
            authorName: 'Lesley Robertson',
            authroRole: 'Client',
            review: 'Very pleased with excellent work. Clean, tidy and very efficient. Am already planning more decorating work with this company. Very glad I found them.',
            rating: 5
        },
        {
            id: 3,
            authorImg: author3img,
            authorName: 'Annie O\'Brien',
            authroRole: 'Client',
            review: 'We at Jordan & Halstead have worked with PropertyPac for a number of years and always pleased with their service, highly recommended.',
            rating: 5
        },
        {
            id: 3,
            authorImg: author3img,
            authorName: 'Michelle Cairns',
            authroRole: 'Client',
            review: 'Fair price and excellent quality, would recommend and use again.',
            rating: 5
        },
        {
            id: 3,
            authorImg: author3img,
            authorName: 'Matin Addo',
            authroRole: 'Client',
            review: 'Thank you Property Pac for renovation and decoration work at my property.  You and your team have done a great job! Please express our thanks to everyone involved. We really appreciate your professional and person service and look forward to working with you again in the future.',
            rating: 5
        },
        {
            id: 3,
            authorImg: author3img,
            authorName: 'Carolina Anna',
            authroRole: 'Client',
            review: 'Completely renovated my kitchen, giving my home a real modern look, the process was so simple and easy. Again, a  huge thanks to Chris for being so easy to work with. Would recommend to anyone who is in need of renovation.',
            rating: 5
        },
        {
            id: 3,
            authorImg: author3img,
            authorName: 'Adam Clarkson',
            authroRole: 'Client',
            review: 'Dealt with Chris for a couple of years now, always available to take my call and quality of work is top notch, would not go anywhere else! 5*',
            rating: 5
        },
        {
            id: 3,
            authorImg: author3img,
            authorName: 'Mel Edwards',
            authroRole: 'Client',
            review: 'Quick to respond, attention to detail. Great service.',
            rating: 5
        }
        
    ]
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col md={12}>
                        <SectionTitle
                            {...SectionTitleStyle}
                            subtitle="TESTIMONIALS"
                            title="Why do people <span>love Property Pac?</span>"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <SwiperSlider settings={slider} {...sliderStyle}>
                            {testimonials.map((testimonial, i) => {
                                return(
                                    <div key={i} className="item">
                                        <Testimonial
                                            authorImg={testimonial.authorImg}
                                            authorName={testimonial.authorName}
                                            authroRole={testimonial.authroRole}
                                            review={testimonial.review}
                                            rating={testimonial.rating}
                                        />
                                    </div>
                                )
                            })}
                        </SwiperSlider>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

Section.propTypes = {
    SectionTitleStyle: PropTypes.object   
} 

Section.defaultProps = {
    SectionTitleStyle: {
        mb: '40px'
    },
    slider: {
        slidesPerView: 2,
        loop: true,
        speed: 1000,
        spaceBetween: 30,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        breakpoints: {
            320: {
                slidesPerView : 1
            },
            991: {
                slidesPerView : 2
            }
        }
    },
    sliderStyle: {
        pagination: {
            mt: '30px'
        }
    }
}


export default Section;